<template>
  <generic-picker nav1="DIRECTORIO" myUrl="dirPicker" centroUrl="dirPickCentro" />
</template>

<script lang="ts">
import {
  defineComponent
} from "@/components/CyanPageWrapper.vue";
import GenericPicker from "@/components/GenericPicker.vue";

export default defineComponent({
  name: "DirPicker",
  components: {
    GenericPicker,
  },
});
</script>
