<template>
  <ion-page :key="myUrl + '-' + this.seed"><cyan-page-wrapper :title="title" :backRoute="backRoute" :nav1="nav1" :ambito="ambito">
    <territory-picker :nextRoute="nextRoute" :seedTP="seed" />
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store
} from "@/components/CyanPageWrapper.vue";
import TerritoryPicker from "@/components/TerritoryPicker.vue";
import cyanRegions, { territorios } from '@/modules/cyanRegions'

export default defineComponent({
  name: "GenericPicker",
  components: {
    TerritoryPicker,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    seed(): string {
      return this.$route.params.seedPicker+'' || cyanRegions.base;
    }, 

    ambito() {
			if (this.seed in territorios) {
				return territorios[(this as any).seed].nombre;
			}
			return '';
		},

    largoDestino() {
      if (! (this.seed.length in cyanRegions.divisiones)) return cyanRegions.largoFinal;
      const lh = cyanRegions.divisiones[this.seed.length].largoHijos;
      return lh || cyanRegions.largoFinal; 

    },

    title() {

      const base = 'Elegir ' + cyanRegions.divisiones[this.largoDestino+''].nombre

			if (this.ambito) {
				return base + ' (' + this.ambito + ')';
			}
			return base;
		},
		userLevel() {
      return store.state.userData.nivel || cyanRegions.base || '';
    },
		backRoute(): string {
			if (this.userLevel.length >= this.seed.length) return '/home';
      
      if (! (this.seed.length in cyanRegions.divisiones)) return '/home';
      const lp = cyanRegions.divisiones[this.seed.length].largoPadre;
      if (!lp) return '/home';
      return '/' + this.myUrl +'/' + (this.seed as unknown as string).substring(0, lp);
		},
    nextRoute(): string {
      return (this as any).largoDestino == cyanRegions.largoFinal ? '/' + (this as any).centroUrl +'/' : '/' + (this as any).myUrl +'/';
    }

  },
  methods: {},
  props: {
	myUrl: {
		type: String,
		default: 'home'
	},
	centroUrl: {
		type: String,
		default: 'home'
	},
	nav1: {
		type: String,
		default: 'TERRITORIOS'
	}

  }
});
</script>
